<template>
    <div>
        <v-card>
            <v-card-title><span class="primary--text">{{$t('caption.addOathCase')}}</span></v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-menu
                            v-model="requestDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="oathCase.appointmentDate"
                                    :label="$t('text.requestDate')"
                                    :prepend-icon="icons.mdiCalendarAlert"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                <v-date-picker
                                    v-model="oathCase.appointmentDate"
                                    @input="requestDateMenu = false"
                                ></v-date-picker>
                        </v-menu>                                  
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            v-model="oathCase.requestingInstitution"
                            outlined                                               
                            label="ស្ថាប័នដែលស្នើសុំសច្ចាប្រណិធាន"
                            :items="institutionList"
                            item-text="{}"
                            hide-details
                            dense
                        >
                            <template slot="selection" slot-scope="data">
                                {{data.item}}
                            </template>
                            <template slot="item" slot-scope="data">
                                {{data.item}}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn 
                            class="primary--text" 
                            rounded
                            outlined
                            @click="openOathDialog"
                        >
                            <v-icon left>{{icons.mdiAttachment}}</v-icon>
                            Attach Persons Who Swear
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-dialog 
                v-model="oathDialog"
                width="800"
            >
                <v-card>
                    <v-card-title><span class="primary--text">Add Persons</span>
                        <v-spacer></v-spacer>
                        <v-btn 
                            rounded 
                            class="primary--text"                           
                            @click="addPerson($event)"
                        >
                            <v-icon dark left>{{icons.mdiPlus}}</v-icon> 
                            Add
                        </v-btn>

                    </v-card-title>
                    <v-card-text>
                        <div v-for="(item, index) in oatPersonItemList" :key="item.id">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field
                                        v-model="item.fullName"
                                        label="Full Name"
                                        hide-details
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-radio-group row v-model="item.gender">
                                        <v-radio :label="$t('text.male')" value="M"></v-radio>
                                        <v-radio :label="$t('text.female')" value="F"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field
                                        v-model="item.age"
                                        label="Age"
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" class="my-5">
                                    <v-btn small rounded  class="primary--text"><v-icon dark>{{icons.mdiDelete}}</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn class="primary" @click="savePerson">Save</v-btn>
                        <v-btn class="secondary" @click="oathDialog=false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-card-title class="justify-center">
                <span class="primary--text mr-2">ការអនុវត្តកិច្ចការ </span>
                <v-btn small rounded outlined class="primary--text" @click="addAction"> <v-icon dark left>{{icons.mdiPlus}}</v-icon>Add</v-btn>
            </v-card-title>
          <!--  <v-divider width="200"></v-divider> -->
            <v-card-text>
                <div v-for="(item,index) in oathCase.action" :key="item.id">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field 
                            label="លេខលិខិត"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                            v-model="item.letterDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.letterDate"
                                    :label="$t('text.letterDate')"
                                    :prepend-icon="icons.mdiCalendarAlert"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                <v-date-picker
                                    v-model="item.letterDate"
                                    @input="item.letterDateMenu = false"
                                ></v-date-picker>
                        </v-menu>   
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                            v-model="item.actionDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.actionDate"
                                    :label="$t('text.letterDate')"
                                    :prepend-icon="icons.mdiCalendarAlert"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                <v-date-picker
                                    v-model="item.actionDate"
                                    @input="item.actionDateMenu = false"
                                ></v-date-picker>
                        </v-menu>   
                    </v-col>
                </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn class="btn primary" @click="saveOathCase">Save</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { mdiCalendarAlert, mdiAttachment, mdiPlus, mdiMinus, mdiDelete } from '@mdi/js';

export default {
  name: 'add-oath-case',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiAttachment,
        mdiPlus,
        mdiMinus,
        mdiDelete,
      },
      requestDateMenu: false,
      letterDateMenu: false,
      actionDateMenu: false,
      oathCase: { action: [{}] },
      oathDialog: false,
      oatPersonItemList: [{}],
      institutionList: [],
    };
  },
  methods: {
    openOathDialog() {
      // alert('open');
      this.oathDialog = true;
    },
    addPerson() {
      this.oatPersonItemList.push({});
    },
    removePerson(item) {
      this.oatPersonItemList.slice(item);
    },
    savePerson() {
      alert(JSON.stringify(this.oatPersonItemList));
    },
    addAction() {
      this.oathCase.action.push({});
    },
    saveOathCase() {
      alert(JSON.stringify(this.oathCase));
    },
  },
};
</script>
